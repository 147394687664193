<template>
  <v-container grid-list-s text-xs-center class="pt-16">
    <v-layout wrap class="px-3">
      <v-row align="start" class="col-12">
        <h2
          class="text-h3 text-capitalize font-weight-black primary--text px-0"
        >
          {{ $t('wasiaHelp') }}
        </h2>
      </v-row>
      <v-row class="col-12"> <v-divider /> </v-row>
      <v-row class="pa-4 col-12">
        <!-- eslint-disable vue/no-v-html -->
        <p v-html="wasiaHelp" />
        <!-- eslint-enable -->
      </v-row>
      <v-row class="pa-4 justify-center pb-10">
        <DownloadWasiaTemplateButton />
      </v-row>
    </v-layout>
  </v-container>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import DownloadWasiaTemplateButton from '@/components/DownloadWasiaTemplateButton.vue'

export default Vue.extend({
  components: { DownloadWasiaTemplateButton },
  computed: {
    ...mapGetters(['wasiaHelp'])
  },
  created() {
    this.getWasiaHelp()
  },
  methods: {
    ...mapActions(['getWasiaHelp'])
  }
})
</script>
